/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'

import { CardField, ExpirationField, CVCField, FormErrorMessage } from './style.js'
import { getCustomFieldsStyle } from './customFieldsStyle.js'
import Saferpay3DSContainer from './containers/saferpay3DS.js'
import { FormGroup, FormLabel, FormRow } from 'Rentlio/components/UI/form'
import { logErrorWithJsonData } from 'Rentlio/utils/logging'

import { LanguageContext } from 'Rentlio/context/LanguageContext'

class SaferpayPayment extends React.PureComponent {
  static contextType = LanguageContext
  saferpay = null

  state = {
    formErrorMessage: '',
    saferpayFieldsKeys: [0, 1, 2]
  }

  componentDidMount = () => {
    this.initializeSaferpayFields()
  }

  onBookPressed = (guestDetails, paymentDetails) => {
    const { receipt, saferpayAliasInsert, openSaferpay3DSModal } = this.props
    this.setState({ formErrorMessage: '' })

    window.SaferpayFields.submit({
      onSuccess: evt => {
        saferpayAliasInsert({
          bookingEngineId: receipt.bookingEngineId,
          saferFieldsToken: evt.token
        }).then(res => {
          this.remountCardFields()
          if (res?.response?.Redirect?.RedirectUrl && res?.response?.Token) {
            openSaferpay3DSModal({
              redirectUrl: res?.response?.Redirect?.RedirectUrl,
              guestDetails,
              paymentDetails: {
                ...paymentDetails,
                aliasToken: res?.response?.Token
              }
            })
          } else {
            logErrorWithJsonData(`Unexpected response from AliasInsert`, res, {
              critical: 'true',
              'payment-method': 'Saferpay',
              description: 'Error occurred when handling the response in Rentlio. Reservation is not created.',
              'possible-reason': 'RedirectUrl or Token was empty'
            })
            this.updateErrorMessage('There was an error during booking')
          }
        })
      },
      onError: (/* evt */) => {
        this.updateErrorMessage()
      }
    })
  }

  initializeSaferpayFields = () => {
    const { translate } = this.context
    const { theme, paymentMethodSettings } = this.props

    window.SaferpayFields.init({
      accessToken: paymentMethodSettings.token,
      url: `${window.extraInfo.SAFERPAY_BASE_URL}/Fields/${paymentMethodSettings.customerId}`,
      style: getCustomFieldsStyle(theme),
      paymentMethods: paymentMethodSettings.selectedCardTypes.split(','),
      placeholders: {
        cardnumber: '0000 0000 0000 0000',
        expiration: translate('MM/YY'),
        cvc: 'CVC'
      }
    })
  }

  remountCardFields = () => {
    // destroy react nodes for saferpay card elements and remount them to be able to initialize new fields
    // because when submit is called, saferpay disables all fields and in case of error I want to enable them for user to try again
    this.setState(
      ({ saferpayFieldsKeys }) => ({
        saferpayFieldsKeys: saferpayFieldsKeys.map(key => key + 3)
      }),
      this.initializeSaferpayFields
    )
  }

  updateErrorMessage = errorMessage => {
    this.setState({ formErrorMessage: errorMessage || '' })
    this.props.onSaferpayPaymentFailed()
  }

  render() {
    const { translate } = this.context
    const { formErrorMessage, saferpayFieldsKeys } = this.state

    return (
      <>
        <Saferpay3DSContainer
          callBookReservationApi={this.props.callBookReservationApi}
          updateErrorMessage={this.updateErrorMessage}
        />
        <FormRow>
          <FormGroup fullWidth={true} key={saferpayFieldsKeys[0]}>
            <FormLabel>
              <label htmlFor='fields-card-number'>{translate('Card number')}</label>
            </FormLabel>
            <CardField id='fields-card-number' className={'field'} />
          </FormGroup>
        </FormRow>

        <FormRow>
          <FormGroup fullWidth={true} key={saferpayFieldsKeys[1]}>
            <FormLabel>
              <label htmlFor='fields-expiration'>{translate('Expiration date')}</label>
            </FormLabel>
            <ExpirationField id='fields-expiration' className={'field'} />
          </FormGroup>

          <FormGroup fullWidth={true} key={saferpayFieldsKeys[2]}>
            <FormLabel>
              <label htmlFor='fields-cvc'>{translate('CVC')}</label>
            </FormLabel>
            <CVCField id='fields-cvc' className={'field'} />
          </FormGroup>
        </FormRow>
        <FormErrorMessage>{translate(formErrorMessage)}</FormErrorMessage>
      </>
    )
  }
}

SaferpayPayment.propTypes = {
  currency: PropTypes.string,
  paymentMethodSettings: PropTypes.object,
  theme: PropTypes.object,
  locale: PropTypes.string,
  cardholderName: PropTypes.string,
  getValidationError: PropTypes.func,
  receipt: PropTypes.object,
  lastName: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  country: PropTypes.string,
  handleAddress: PropTypes.func,
  handleCity: PropTypes.func,
  handlePostalCode: PropTypes.func,
  address: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  callBookReservationApi: PropTypes.func,
  saferpayAliasInsert: PropTypes.func,
  openSaferpay3DSModal: PropTypes.func,
  onSaferpayPaymentFailed: PropTypes.func,
  saferpayState: PropTypes.object
}

export default withTheme(SaferpayPayment)

import styled from 'styled-components'

const InputField = styled.div`
  height: 60px;
  width: 100%;
`

export const CardHolderField = styled(InputField)``
export const CardField = styled(InputField)``
export const ExpirationField = styled(InputField)``
export const CVCField = styled(InputField)``

export const OptionalField = styled.div`
  height: ${({ hide }) => (hide ? '0' : 'auto')};
  overflow: hidden;
`

export const FormErrorMessage = styled.p`
  width: 100%;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.alertColor};
  font-size: ${({ theme }) => theme.fonts._14};
  line-height: ${({ theme }) => theme.fonts._18};
`

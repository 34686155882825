import PropTypes from 'prop-types'
import React, { useEffect, useState, useCallback } from 'react'
import { PreviewIframe, PreviewWrapper } from './style'
import Modal from 'Rentlio/components/Modal'
import Animation from 'Rentlio/components/UI/Animation'
import { modals } from 'Rentlio/screen/layout/utils/modalsEnum'
import { breakpoint as responsive, device } from 'Rentlio/style/responsive'

const Saferpay3DS = ({ closeModal, activeModal, modalData, updateErrorMessage, callBookReservationApi }) => {
  const mediaMatchQuery = window.matchMedia(device.laptop)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= responsive.laptop)

  useEffect(() => {
    window.addEventListener('message', handleSaferpayRedirect)

    // check if we are on mobile
    checkMediaWidth(mediaMatchQuery)
    // NOTE: Backward compatibility for older version of safari (13 etc. - chrome and firefox are ok here) - don't remove !
    // Please be aware that the MediaQueryList.addListener() method has been deprecated.
    // https://www.w3.org/TR/cssom-view-1/#mediaquerylist
    if (mediaMatchQuery?.addEventListener) {
      mediaMatchQuery.addEventListener('change', checkMediaWidth)
    } else {
      mediaMatchQuery.addListener(checkMediaWidth)
    }

    return () => {
      // Anything in here is fired on component unmount
      removeEventListeners()
      // remove media query listeners
      if (mediaMatchQuery?.addEventListener) {
        mediaMatchQuery.removeEventListener('change', checkMediaWidth)
      } else {
        mediaMatchQuery.removeListener(checkMediaWidth)
      }
    }
  }, [])

  const removeEventListeners = () => {
    window.removeEventListener('message', handleSaferpayRedirect)
  }

  const checkMediaWidth = (matchQuery = mediaMatchQuery) => {
    if (matchQuery.matches) {
      return setIsMobile(true)
    }
    setIsMobile(false)
  }

  const cancelAndCloseModal = () => {
    updateErrorMessage('Card authorisation error')
    removeEventListeners()
    closeModal()
  }

  const handleSaferpayRedirect = useCallback(event => {
    const receivedData = event.data
    if (receivedData.message === 'close') {
      callBookReservationApi(modalData.guestDetails, modalData.paymentDetails)
      closeModal()
    }
  }, [])

  return (
    <Animation show={activeModal === modals.SAFERPAY_3DS} animationType={'fadeInOut'} isModal>
      <Modal
        hideModalHandler={cancelAndCloseModal}
        show={activeModal === modals.SAFERPAY_3DS}
        padding={isMobile ? '0px 50px 0px 0px' : '10px 10px 0px 15px'}
        paddingBottom={'0px'}
        isLarge
      >
        <PreviewWrapper>
          {modalData.redirectUrl && <PreviewIframe src={modalData.redirectUrl} width='100%' height='600px' />}
        </PreviewWrapper>
      </Modal>
    </Animation>
  )
}

Saferpay3DS.propTypes = {
  closeModal: PropTypes.func,
  activeModal: PropTypes.string,
  modalData: PropTypes.object,
  callBookReservationApi: PropTypes.func,
  updateErrorMessage: PropTypes.func,
  onM2PayPaymentFailed: PropTypes.func
}

export default Saferpay3DS

import React from 'react'
import PropTypes from 'prop-types'

const AmericanExpressCardIcon = ({ width, height }) => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    x='0'
    y='0'
    width={width}
    height={height}
    viewBox='0 0 45 45'
    xmlSpace='preserve'
  >
    <path id='logo-blue-box-small-45-9x45-a' fill='#fff' d='M44.9 44.9H0V0h44.9z' />
    <path fill='#006fcf' d='M44.9 24.2V-.1H0v44.9h44.9V31.7c-.1 0 0-7.5 0-7.5' transform='translate(0 .12)' />
    <path
      fill='#fff'
      d='M39.4 21.7h3.4v-7.9h-3.7v1.1l-.7-1.1h-3.2v1.4l-.6-1.4h-5.9c-.2 0-.4.1-.6.1s-.3.1-.5.2-.3.1-.5.2v-.5H10.2l-.5 1.3-.5-1.3h-4v1.4l-.6-1.4H1.4L0 17.2v4.5h2.3l.4-1.1h.8l.4 1.1h17.6v-1l.7 1h4.9v-.6c.1.1.3.1.4.2s.3.1.4.2c.2.1.4.1.6.1h3.6l.4-1.1h.8l.4 1.1h4.9v-1l.8 1.1zm5.5 10v-7.4H17.4l-.7 1-.7-1H8v7.9h8l.7-1 .7 1h5v-1.7h-.2c.7 0 1.3-.1 1.8-.3v2.1h3.6v-1l.7 1h14.9c.6-.2 1.2-.3 1.7-.6z'
      transform='translate(0 .12)'
    />
    <path
      fill='#006fcf'
      d='M43.2 29.8h-2.7v1.1h2.6c1.1 0 1.8-.7 1.8-1.7s-.6-1.5-1.6-1.5h-1.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2.3l.5-1.1h-2.7c-1.1 0-1.8.7-1.8 1.6 0 1 .6 1.5 1.6 1.5h1.2c.3 0 .5.2.5.5.1.4-.1.6-.5.6zm-4.9 0h-2.7v1.1h2.6c1.1 0 1.8-.7 1.8-1.7s-.6-1.5-1.6-1.5h-1.2c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h2.3l.5-1.1h-2.7c-1.1 0-1.8.7-1.8 1.6 0 1 .6 1.5 1.6 1.5h1.2c.3 0 .5.2.5.5.1.4-.2.6-.5.6zm-3.5-3.2v-1.1h-4.2v5.3h4.2v-1.1h-3v-1.1h2.9v-1.1h-2.9v-1h3v.1zm-6.8 0c.5 0 .7.3.7.6s-.2.6-.7.6h-1.5v-1.3l1.5.1zm-1.5 2.3h.6l1.6 1.9h1.5l-1.8-2c.9-.2 1.4-.8 1.4-1.6 0-1-.7-1.7-1.8-1.7h-2.8v5.3h1.2l.1-1.9zm-3.2-1.6c0 .4-.2.7-.7.7H21v-1.4h1.5c.5 0 .8.3.8.7zm-3.5-1.8v5.3H21V29h1.6c1.1 0 1.9-.7 1.9-1.8 0-1-.7-1.8-1.8-1.8l-2.9.1zM18 30.8h1.5l-2.1-2.7 2.1-2.6H18l-1.3 1.7-1.3-1.7h-1.5l2.1 2.6-2.1 2.6h1.5l1.3-1.7 1.3 1.8zm-4.5-4.2v-1.1H9.3v5.3h4.2v-1.1h-3v-1.1h2.9v-1.1h-2.9v-1h3v.1zm24.3-9.4l2.1 3.2h1.5v-5.3h-1.2v3.5l-.3-.5-1.9-3h-1.6v5.3h1.2v-3.6l.2.4zm-5.2-.1L33 16l.4 1.1.5 1.2h-1.8l.5-1.2zm2.1 3.3H36l-2.3-5.3h-1.6l-2.3 5.3h1.3l.5-1.1h2.6l.5 1.1zm-5.6 0l.5-1.1h-.3c-.9 0-1.4-.6-1.4-1.5v-.1c0-.9.5-1.5 1.4-1.5h1.3v-1.1h-1.4c-1.6 0-2.5 1.1-2.5 2.6v.1c0 1.6.9 2.6 2.4 2.6zm-4.5 0h1.2v-5.2h-1.2v5.2zM22 16.2c.5 0 .7.3.7.6s-.2.6-.7.6h-1.5v-1.3l1.5.1zm-1.5 2.3h.6l1.6 1.9h1.5l-1.8-2c.9-.2 1.4-.8 1.4-1.6 0-1-.7-1.7-1.8-1.7h-2.8v5.3h1.2l.1-1.9zm-2.2-2.3v-1.1h-4.2v5.3h4.2v-1.1h-3v-1.1h2.9v-1.1h-2.9v-1h3v.1zm-9.1 4.2h1.1l1.5-4.3v4.3H13v-5.3h-2l-1.2 3.6-1.2-3.6h-2v5.3h1.2v-4.3l1.4 4.3zm-6.5-3.3l.4-1.1.4 1.1.5 1.2H2.2l.5-1.2zm2.1 3.3h1.3l-2.3-5.3H2.3L0 20.4h1.3l.5-1.1h2.6l.4 1.1z'
      transform='translate(0 .12)'
    />
  </svg>
)

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

const defaultProps = {
  width: 45,
  height: 45
}

AmericanExpressCardIcon.propTypes = propTypes
AmericanExpressCardIcon.defaultProps = defaultProps
export default AmericanExpressCardIcon

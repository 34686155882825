import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Contact from './screen/layout/containers/contact'
import Footer from './screen/layout/containers/footer'
import MonriFooter from './screen/layout/containers/monriFooter'
import Header from './screen/layout/containers/header'
import HeroContainer from './screen/layout/containers/hero'
import GlobalStyle from './style/globalStyle'
import Theme from './style/theme'
import CookieNoticeContainer from './components/CookieNotice/container/cookieNotice'
import WspayFooter from './screen/layout/containers/wspayFooter'
import SaferpayFooter from './screen/layout/containers/saferpayFooter'
import GuestRegistrationContainer from 'Rentlio/screen/guestRegistration/containers/guestRegistration'
import OfferPaymentContainer from 'Rentlio/screen/offerPayment/containers/offerPayment'
import OfferContainer from 'Rentlio/screen/offer/containers/offer'
import PaymentContainer from 'Rentlio/screen/payment/containers/payment'
import {
  HOME_PAGE,
  PAYMENT_PAGE,
  SERVICES_PAGE,
  RESERVATIONS_PAGE,
  OFFER_PAGE,
  OFFER_PAYMENT_PAGE,
  GUEST_REGISTRATION_PAGE
} from 'Rentlio/utils/routes'
import ServicesContainer from 'Rentlio/screen/services/containers/services'
import UnitTypesContainer from 'Rentlio/screen/accomodation/containers/unitTypes'
import ReservationsContainer from 'Rentlio/screen/reservations/containers/reservations'
import LanguageProvider from 'Rentlio/context/LanguageContext'
import CurrenciesProvider from 'Rentlio/context/CurrenciesContext'
import TermsOfServiceContainer from 'Rentlio/screen/layout/containers/termsOfService'
import PrivacyPolicyContainer from 'Rentlio/screen/layout/containers/privacyPolicy'
const AppWrapper = styled.div`
  margin: 0;
  padding: 0;
`

export default function App() {
  return (
    <Theme>
      <BrowserRouter>
        <React.Fragment>
          <GlobalStyle />
          <LanguageProvider>
            <CurrenciesProvider>
              <AppWrapper>
                <Header />
                <HeroContainer />
                <TermsOfServiceContainer />
                <PrivacyPolicyContainer />
                <Switch>
                  <Route path={PAYMENT_PAGE}>
                    <PaymentContainer />
                  </Route>
                  <Route path={SERVICES_PAGE}>
                    <ServicesContainer />
                  </Route>
                  <Route path={HOME_PAGE} exact={true}>
                    <UnitTypesContainer />
                  </Route>
                  <Route path={RESERVATIONS_PAGE} exact={true}>
                    <ReservationsContainer />
                  </Route>
                  <Route path={OFFER_PAGE} exact={true}>
                    <OfferContainer />
                  </Route>
                  <Route path={OFFER_PAYMENT_PAGE} exact={true}>
                    <OfferPaymentContainer />
                  </Route>
                  <Route path={GUEST_REGISTRATION_PAGE} exact={true}>
                    <GuestRegistrationContainer />
                  </Route>
                </Switch>
                <Contact />
                <Footer />
                <CookieNoticeContainer />
                <SaferpayFooter />
                <WspayFooter />
                <MonriFooter />
              </AppWrapper>
            </CurrenciesProvider>
          </LanguageProvider>
        </React.Fragment>
      </BrowserRouter>
    </Theme>
  )
}

import styled from 'styled-components'

export const SpinnerOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 1);
  z-index: 100;
  overflow: hidden;
`

export const Overlay = styled.div`
  display: block;
  z-index: 100;
  position: fixed;
  background: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

import * as Sentry from '@sentry/browser'

const sensitiveDataKeys = ['pan_token', 'token']
const tokenRegex = /\b[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}\b/

const filterSensitiveData = (data, seen = new Set()) => {
  if (typeof data !== 'object' || data === null || seen.has(data)) {
    return
  }

  seen.add(data)

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      if (sensitiveDataKeys.includes(key)) {
        delete data[key]
      } else if (typeof data[key] === 'string' && tokenRegex.test(data[key])) {
        data[key] = data[key].replace(tokenRegex, '[FILTERED]')
        // replace all the sensitive strings in messages
        sensitiveDataKeys.forEach(sensitive => {
          const regex = new RegExp(sensitive, 'g')
          data[key] = data[key].replace(regex, '[FILTERED]')
        })
      } else if (typeof data[key] === 'object' && data[key] !== null) {
        filterSensitiveData(data[key], seen)
      }
    }
  }

  seen.delete(data)
}

function flattenObject(obj, parentKey = '', result = {}, seen = new Set()) {
  if (typeof obj !== 'object' || obj === null || seen.has(obj)) {
    return
  }

  seen.add(obj)

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = parentKey ? `${parentKey}.${key}` : key

      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
        flattenObject(obj[key], newKey, result, seen)
      } else if (Array.isArray(obj[key])) {
        result[newKey] = obj[key].slice()
      } else {
        result[newKey] = obj[key]
      }
    }
  }

  seen.delete(obj)
  return result
}

export const logErrorWithJsonData = (error, jsonData, tags = {}) => {
  Sentry.withScope(scope => {
    for (const [key, value] of Object.entries(tags)) {
      scope.setTag(key, value)
    }

    filterSensitiveData(jsonData)

    scope.setExtras({
      data: flattenObject(jsonData),
      stringified: JSON.stringify(jsonData)
    })

    if (typeof error === 'string') {
      Sentry.captureException(new Error(error))
    } else {
      Sentry.captureException(error)
    }
  })
}

import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SaferpayFooter from 'Rentlio/screen/layout/components/SaferpayFooter'
import { BE_SAFERPAY } from 'Rentlio/utils/paymentTypesEnum'

const SaferpayFooterContainer = ({ paymentMethods, locale }) => {
  const supportedCards = paymentMethods.find(method => Number(method.id) === BE_SAFERPAY)
  return supportedCards ? (
    <SaferpayFooter locale={locale} selectedCardTypes={supportedCards?.settings?.selectedCardTypes} />
  ) : null
}

const mapStateToProps = state => ({
  locale: state.configuration.locale,
  paymentMethods: state.paymentMethods
})

SaferpayFooterContainer.propTypes = {
  paymentMethods: PropTypes.array,
  locale: PropTypes.string
}

export default connect(mapStateToProps, null)(SaferpayFooterContainer)

import styled from 'styled-components'
import { device } from 'Rentlio/style/responsive'

export const PreviewWrapper = styled.div`
  width: 100%;
  min-height: 600px;

  @media ${device.tablet} {
    width: 100%;
    min-height: 600px;
    scale: 0.7;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const PreviewIframe = styled.iframe`
  border: none;
  animation: ${({ theme }) => theme.animations.fadeInOut} 0.4s ease-in-out;

  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: -40px;
  }
`

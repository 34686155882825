import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import TermsAndConditions from 'Rentlio/screen/payment/components/Payment/PaymentInfo/TermsAndConditions'
import BankTransfer from 'Rentlio/components/BankTransfer'
import CreditCard from 'Rentlio/components/CreditCard'
import RadioGroup from 'Rentlio/components/UI/RadioGroup'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import { Title } from 'Rentlio/screen/offerPayment/components/OfferPayment/style'
import getCardExpirationDateValue from 'Rentlio/screen/payment/components/Payment/PaymentInfo/helper/getCardExpirationDateValue'
import {
  BE_CREDIT_CARD,
  BE_BANK_TRANSFER,
  BE_MONRI,
  BE_STRIPE,
  BE_M2PAY,
  BE_WSPAY,
  BE_SAFERPAY
} from 'Rentlio/utils/paymentTypesEnum'
import MonriPayment from 'Rentlio/screen/payment/components/Payment/MonriPayment'
import StripePayment from 'Rentlio/screen/payment/components/Payment/StripePayment'
import M2PayPayment from 'Rentlio/screen/payment/components/Payment/M2PayPayment'
import SaferpayPayment from 'Rentlio/screen/payment/components/Payment/Saferpay'
import { isOfferStillActive } from 'Rentlio/utils/bookingTypeEnum'

class PaymentMethod extends Component {
  static contextType = LanguageContext
  state = {
    firstName: '',
    lastName: '',
    country: '',
    estimatedTimeOfArrival: '',
    note: '',
    cardNumber: '',
    termsAccepted: false,
    loading: false,
    errors: {},
    gtmCheckoutEventSent: false
  }

  getSelectedPaymentGateway = () => {
    const {
      paymentMethods,
      bookingEngine,
      inputData: { selectedPaymentMethod }
    } = this.props
    if (selectedPaymentMethod === BE_MONRI) {
      return paymentMethods.find(item => item.bookingEngineId === bookingEngine.id && item.settings.authenticityToken)
    }
    if (selectedPaymentMethod === BE_STRIPE) {
      return paymentMethods.find(item => item.bookingEngineId === bookingEngine.id && item.settings.publishableApiKey)
    }
    if (selectedPaymentMethod === BE_M2PAY) {
      return paymentMethods.find(item => item.bookingEngineId === bookingEngine.id && item.settings.merchantID)
    }
    if (selectedPaymentMethod === BE_WSPAY) {
      return paymentMethods.find(item => item.bookingEngineId === bookingEngine.id && item.settings.shopId)
    }
    if (selectedPaymentMethod === BE_SAFERPAY) {
      return paymentMethods.find(item => item.bookingEngineId === bookingEngine.id && item.settings.customerId)
    }
  }

  componentDidMount = () => {
    this.setPaymentDetailsFromProps()
    this.fillSelectedPaymentMethod()
  }

  onCardholderSameAsGuestClicked = () => {
    const {
      inputData: { cardholderSameAsGuest },
      setData
    } = this.props

    setData({ cardholderSameAsGuest: !cardholderSameAsGuest })
  }

  fillSelectedPaymentMethod = () => {
    const paymentGateway = this.getSelectedPaymentGateway()
    if (paymentGateway) {
      this.props.setData({ selectedPaymentMethod: paymentGateway.id })
    }
  }

  setPaymentDetailsFromProps = () => {
    const {
      firstName,
      lastName,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note
    } = this.props.paymentDetails

    this.setState({
      firstName,
      lastName,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note
    })
  }

  isCreditCardSelected = () => {
    const {
      inputData: { selectedPaymentMethod },
      paymentMethods
    } = this.props
    return selectedPaymentMethod === BE_CREDIT_CARD && paymentMethods.some(item => item.id === BE_CREDIT_CARD)
  }

  isBankTransferSelected = () => {
    const {
      inputData: { selectedPaymentMethod },
      paymentMethods
    } = this.props
    return selectedPaymentMethod === BE_BANK_TRANSFER && paymentMethods.some(item => item.id === BE_BANK_TRANSFER)
  }

  handleCreditCardExpirationDate = e => {
    const {
      setData,
      inputData: { expirationDate }
    } = this.props
    const newExpirationDate = getCardExpirationDateValue(e.target.value, expirationDate)
    setData({ expirationDate: newExpirationDate })
  }

  handleCvvNumber = e => {
    const {
      setData,
      inputData: { cvv }
    } = this.props
    setData({ cvv: e.target.value.length > 4 ? cvv : e.target.value })
  }

  handleSelectPaymentMethods = item => {
    const { isEmptyMonri, setTermsRead } = this.props
    this.props.setData({ selectedPaymentMethod: item })
    if (isEmptyMonri || item === BE_MONRI || item === BE_WSPAY) {
      this.props.setData({
        termsAccepted: false
      })
      setTermsRead(false)
    }
  }

  callAcceptBookingOfferApi = (guestDetails, paymentDetails) => {
    const { acceptBookingOffer, locale, bookingCode, offerUuid, setData } = this.props
    acceptBookingOffer(offerUuid, bookingCode, { guestDetails, payment: paymentDetails })
      .then(({ success, response }) => {
        if (!success) {
          setData({ loading: false })

          return
        }

        window.location.replace(`/reservations/${response.publicId}?language=${locale}`)
      })
      .finally(() => setData({ loading: false }))
  }

  onPaymentFailed = () => {
    const { setData } = this.props
    setData({ loading: false })
  }

  getPaymentGatewayProps = () => {
    const {
      inputData: {
        email,
        phone,
        cardholderSameAsGuest,
        cardholderName,
        address,
        city,
        postalCode,
        selectedPaymentMethod,
        country,
        firstName,
        lastName,
        note,
        estimatedTimeOfArrival,
        companyName,
        companyVatId,
        isCompany
      },
      getValidationError,
      currency,
      setData,
      locale, // TODO: check what is sent here,
      paymentGateways,
      getMonriClientSecret,
      getStripeClientSecret,
      getM2PayClientSecret,
      openM2Pay3DSModal,
      bookingEngine,
      receipt,
      saferpayAliasInsert,
      openSaferpay3DSModal
    } = this.props

    let paymentGatewayProps = {
      paymentDetails: {
        type: selectedPaymentMethod,
        name: cardholderSameAsGuest ? firstName + ' ' + lastName : cardholderName
      },
      currency: currency,
      paymentMethodSettings: this.getSelectedPaymentGateway().settings,
      locale: locale,
      cardholderSameAsGuest: cardholderSameAsGuest,
      handleSameAsGuest: this.onCardholderSameAsGuestClicked,
      cardholderName: cardholderName,
      handleCardHolderName: e => {
        setData({ cardholderName: e.target.value })
      },
      handleAddress: e => {
        setData({ address: e.target.value })
      },
      handleCity: e => {
        setData({ city: e.target.value })
      },
      handlePostalCode: e => {
        setData({ postalCode: e.target.value })
      },
      getValidationError: getValidationError,
      callBookReservationApi: this.callAcceptBookingOfferApi,
      isWSPay: BE_WSPAY === selectedPaymentMethod,
      bookingEngineId: bookingEngine.id,
      receipt: receipt
    }

    const guestDetails = {
      firstName,
      lastName,
      email,
      phone,
      country: country ? String(country) : country,
      address: address || '',
      city: city || '',
      postalCode: postalCode || '',
      note,
      estimatedTimeOfArrival,
      companyName,
      companyVatId,
      isCompany
    }

    if (selectedPaymentMethod === BE_MONRI || selectedPaymentMethod === BE_WSPAY) {
      paymentGatewayProps = {
        ...paymentGatewayProps,
        ...guestDetails,
        getMonriClientSecret,
        monri: paymentGateways.monri,
        onMonriPaymentFailed: this.onPaymentFailed,
        isWSPay: BE_WSPAY === selectedPaymentMethod
      }
    }

    if (selectedPaymentMethod === BE_STRIPE) {
      paymentGatewayProps = {
        ...paymentGatewayProps,
        guestDetails,
        getStripeClientSecret,
        stripe: paymentGateways.stripe,
        onStripePaymentFailed: this.onPaymentFailed
      }
    }

    if (selectedPaymentMethod === BE_M2PAY) {
      paymentGatewayProps = {
        ...paymentGatewayProps,
        guestDetails,
        onM2PayPaymentFailed: this.onPaymentFailed,
        openM2Pay3DSModal: openM2Pay3DSModal,
        getM2PayClientSecret: getM2PayClientSecret,
        m2pay: paymentGateways.m2pay
      }
    }
    if (selectedPaymentMethod === BE_SAFERPAY) {
      paymentGatewayProps = {
        ...paymentGatewayProps,
        saferpayAliasInsert: saferpayAliasInsert,
        openSaferpay3DSModal: openSaferpay3DSModal,
        onSaferpayPaymentFailed: this.onPaymentFailed
      }
    }

    return paymentGatewayProps
  }

  showCreditCard = () => {
    const {
      inputData: { selectedPaymentMethod },
      termsAccepted,
      isEmptyMonri
    } = this.props

    return BE_CREDIT_CARD === selectedPaymentMethod && (!isEmptyMonri || (isEmptyMonri && termsAccepted))
  }

  render() {
    const {
      inputData: { cardIssuer, cardholderSameAsGuest, cardholderName, selectedPaymentMethod, cvv, expirationDate },
      getValidationError,
      paymentMethods,
      currency,
      prePaymentDetails,
      setData,
      bookingEngine,
      termsAccepted,
      termsScrolledThrough,
      termsRead,
      booking,
      offer,
      isEmptyMonri,
      setHasScroll
    } = this.props

    const { translate } = this.context

    return (
      <>
        <Title>{translate('Select payment method')}</Title>
        {paymentMethods.length > 1 && (
          <RadioGroup
            options={paymentMethods}
            onSelect={this.handleSelectPaymentMethods}
            selected={selectedPaymentMethod}
          />
        )}
        {isOfferStillActive(offer, booking) &&
          bookingEngine.termsOfService?.length > 0 &&
          (BE_MONRI === selectedPaymentMethod ||
            BE_WSPAY === selectedPaymentMethod ||
            (selectedPaymentMethod === BE_CREDIT_CARD && isEmptyMonri)) && (
            <TermsAndConditions
              termsOfService={bookingEngine.termsOfService}
              termsScrolledThrough={termsScrolledThrough}
              termsRead={termsRead}
              setHasScroll={setHasScroll}
            />
          )}
        {(BE_MONRI === selectedPaymentMethod || BE_WSPAY === selectedPaymentMethod) && termsAccepted && (
          <MonriPayment ref={node => (this.monriPayment = node)} {...this.getPaymentGatewayProps()} />
        )}
        {BE_STRIPE === selectedPaymentMethod && (
          <StripePayment ref={node => (this.stripePayment = node)} {...this.getPaymentGatewayProps()} />
        )}
        {BE_M2PAY === selectedPaymentMethod && (
          <M2PayPayment ref={node => (this.m2payPayment = node)} {...this.getPaymentGatewayProps()} />
        )}
        {BE_SAFERPAY === selectedPaymentMethod && (
          <SaferpayPayment ref={node => (this.saferpayPayment = node)} {...this.getPaymentGatewayProps()} />
        )}
        {BE_BANK_TRANSFER === selectedPaymentMethod && (
          <BankTransfer currency={currency} prePaymentDetails={prePaymentDetails} />
        )}
        {this.showCreditCard() && (
          <CreditCard
            cardholderSameAsGuest={cardholderSameAsGuest}
            handleSameAsGuest={this.onCardholderSameAsGuestClicked}
            cardholderName={cardholderName}
            handleCardHolderName={e => {
              setData({ cardholderName: e.target.value })
            }}
            handleCardNumber={e => setData({ cardNumber: e.target.value })}
            cardIssuer={cardIssuer}
            cvv={cvv}
            handleCvvNumber={this.handleCvvNumber}
            expirationDate={expirationDate}
            handleExpirationDate={this.handleCreditCardExpirationDate}
            getValidationError={getValidationError}
          />
        )}
      </>
    )
  }
}

PaymentMethod.propTypes = {
  inputData: PropTypes.object,
  paymentMethods: PropTypes.array,
  getValidationError: PropTypes.func,
  setData: PropTypes.func,
  currency: PropTypes.string,
  prePaymentDetails: PropTypes.object,

  bookReservation: PropTypes.func,
  bookingEngine: PropTypes.object,
  updatePaymentInfo: PropTypes.func,
  paymentDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    country: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    estimatedTimeOfArrival: PropTypes.string,
    note: PropTypes.string,
    cardholderName: PropTypes.string,
    cardNumber: PropTypes.string,
    cvv: PropTypes.string,
    expirationDate: PropTypes.string
  }),
  locale: PropTypes.string,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func,
  clearBasket: PropTypes.func,
  getMonriClientSecret: PropTypes.func,
  paymentGateways: PropTypes.object,
  stripe: PropTypes.object,
  m2pay: PropTypes.object,
  getStripeClientSecret: PropTypes.func,
  getM2PayClientSecret: PropTypes.func,
  sendSuccessfulReservationEvent: PropTypes.func,
  sendPaymentFormInputEvent: PropTypes.func,
  openM2Pay3DSModal: PropTypes.func,
  receipt: PropTypes.object,
  acceptBookingOffer: PropTypes.func,
  bookingCode: PropTypes.string,
  offerUuid: PropTypes.string,
  termsAccepted: PropTypes.bool,
  termsScrolledThrough: PropTypes.func,
  termsRead: PropTypes.bool,
  booking: PropTypes.object,
  offer: PropTypes.object,
  isEmptyMonri: PropTypes.bool,
  setHasScroll: PropTypes.func,
  setTermsRead: PropTypes.func,
  saferpayAliasInsert: PropTypes.func,
  openSaferpay3DSModal: PropTypes.func
}

export default PaymentMethod

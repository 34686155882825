export const ADD_ITEM_TO_BASKET = 'basket/ADD_ITEM_TO_BASKET'
export const REMOVE_ITEM_FROM_BASKET = 'basket/REMOVE_ITEM_FROM_BASKET'
export const CLEAR_BASKET = 'basket/CLEAR_BASKET'
export const UPDATE_SERVICE_QUANTITY = 'basket/UPDATE_SERVICE_QUANTITY'
export const FETCH_AVAILABILITY = 'bookingengine/FETCH_AVAILABILITY'
export const FETCH_AVAILABILITY_COMPLETED = 'bookingengine/FETCH_AVAILABILITY_COMPLETED'
export const FETCH_AVAILABILITY_FAILED = 'bookingengine/FETCH_AVAILABILITY_FAILED'
export const FETCH_AVAILABILITY_FOR_MONTH = 'unitTypeAvailability/FETCH_AVAILABILITY_FOR_MONTH'
export const FETCH_AVAILABILITY_FOR_MONTH_COMPLETED = 'unitTypeAvailability/FETCH_AVAILABILITY_FOR_MONTH_COMPLETED'
export const FETCH_AVAILABILITY_FOR_MONTH_FAILED = 'unitTypeAvailability/FETCH_AVAILABILITY_FOR_MONTH_FAILED'

export const BOOK_RESERVATION = 'payment/BOOK_RESERVATION'
export const BOOK_RESERVATION_COMPLETED = 'payment/BOOK_RESERVATION_COMPLETED'
export const BOOK_RESERVATION_FAILED = 'payment/BOOK_RESERVATION_FAILED'
export const UPDATE_PAYMENT_INFO = 'payment/UPDATE_PAYMENT_INFO'

export const CANCEL_RESERVATION = 'reservation/CANCEL_RESERVATION'
export const CANCEL_RESERVATION_COMPLETED = 'reservation/CANCEL_RESERVATION_COMPLETED'
export const CANCEL_RESERVATION_FAILED = 'reservation/CANCEL_RESERVATION_FAILED'

export const FETCH_RESERVATION = 'reservation/FETCH_RESERVATION'
export const FETCH_RESERVATION_COMPLETED = 'reservation/FETCH_RESERVATION_COMPLETED'
export const FETCH_RESERVATION_FAILED = 'reservation/FETCH_RESERVATION_FAILED'

export const SELECT_LOCALE = 'configuration/SELECT_LOCALE'
export const SELECT_CURRENCY = 'configuration/SELECT_CURRENCY'
export const SET_PROMO_CODE = 'bookingengine/SET_PROMO_CODE'
export const CLEAR_PROMO_CODE = 'bookingengine/CLEAR_PROMO_CODE'

export const OPEN_MODAL = 'modals/OPEN_MODAL'
export const CLOSE_MODAL = 'modals/CLOSE_MODAl'

export const LOADING_DONE = 'animationLoader/LOADING_DONE'

export const FILTER_BY_AMENITY = 'configuration/FILTER_BY_AMENITY'
export const REMOVE_FILTER_BY_AMENITY = 'configuration/REMOVE_FILTER_BY_AMENITY'
export const CLEAR_AMENITY_FILTERS = 'configuration/CLEAR_AMENITY_FILTERS'

export const FILTER_BY_LOCATION = 'configuration/FILTER_BY_LOCATION'

export const FETCH_MONRI_CLIENT_SECRET = 'payment/FETCH_MONRI_CLIENT_SECRET'
export const FETCH_MONRI_CLIENT_SECRET_COMPLETED = 'payment/FETCH_MONRI_CLIENT_SECRET_COMPLETED'
export const FETCH_MONRI_CLIENT_SECRET_FAILED = 'payment/FETCH_MONRI_CLIENT_SECRET_FAILED'

export const FETCH_STRIPE_CLIENT_SECRET = 'payment/FETCH_STRIPE_CLIENT_SECRET'
export const FETCH_STRIPE_CLIENT_SECRET_COMPLETED = 'payment/FETCH_STRIPE_CLIENT_SECRET_COMPLETED'
export const FETCH_STRIPE_CLIENT_SECRET_FAILED = 'payment/FETCH_STRIPE_CLIENT_SECRET_FAILED'

export const FETCH_M2PAY_CLIENT_SECRET = 'payment/FETCH_M2PAY_CLIENT_SECRET'
export const FETCH_M2PAY_CLIENT_SECRET_COMPLETED = 'payment/FETCH_M2PAY_CLIENT_SECRET_COMPLETED'
export const FETCH_M2PAY_CLIENT_SECRET_FAILED = 'payment/FETCH_M2PAY_CLIENT_SECRET_FAILED'

export const OPEN_UNIT_TYPE_MODAL = 'accomodation/OPEN_UNIT_TYPE_MODAL'
export const OPEN_PACKAGE_DEAL_MODAL = 'accomodation/OPEN_PACKAGE_DEAL_MODAL'
export const SEND_SUCCESSFUL_RESERVATION_EVENT = 'events/SEND_SUCCESSFUL_RESERVATION_EVENT'
export const SEND_PAYMENT_FORM_INPUT_EVENT = 'events/SEND_PAYMENT_FORM_INPUT_EVENT'

export const ON_OCCUPANCY_CHANGE = 'request/ON_OCCUPANCY_CHANGE'

export const ACCEPT_BOOKING = 'offer/ACCEPT_BOOKING'
export const ACCEPT_BOOKING_COMPLETED = 'offer/ACCEPT_BOOKING_COMPLETED'
export const ACCEPT_BOOKING_FAILED = 'offer/ACCEPT_BOOKING_FAILED'

export const PRICE_PER_DAY_RESERVATION_CHANGE = 'pricePerDay/PRICE_PER_DAY_RESERVATION_CHANGE'

export const ACTIVATE_COOKIE_NOTICE = 'cookieNotice/ACTIVATE_COOKIE_NOTICE'
export const ACCEPT_COOKIE_NOTICE = 'cookieNotice/ACCEPT_COOKIE_NOTICE'

export const UPDATE_GUEST_REGISTRATION = 'reservation/UPDATE_GUEST_REGISTRATION'
export const UPDATE_GUEST_REGISTRATION_COMPLETED = 'reservation/UPDATE_GUEST_REGISTRATION_COMPLETED'
export const UPDATE_GUEST_REGISTRATION_FAILED = 'reservation/UPDATE_GUEST_REGISTRATION_FAILED'

export const SAFERPAY_ALIAS_INSERT = 'reservation/SAFERPAY_ALIAS_INSERT'
export const SAFERPAY_ALIAS_INSERT_COMPLETED = 'reservation/SAFERPAY_ALIAS_INSERT_COMPLETED'
export const SAFERPAY_ALIAS_INSERT_FAILED = 'reservation/SAFERPAY_ALIAS_INSERT_FAILED'

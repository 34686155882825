import React from 'react'
import AmericanExpressCard from 'Rentlio/components/Icons/AmericanExpressCard'
import Maestro from 'Rentlio/components/Icons/maestro'
import Visa from 'Rentlio/components/Icons/visa'
import Mastercard from 'Rentlio/components/Icons/mastercard'
import Diners from 'Rentlio/components/Icons/dinersCard'

export default [
  {
    value: 1,
    id: 'maestro',
    label: 'Maestro',
    logo: <Maestro width={54} height={45} />,
    link: 'https://www.mastercard.hr/hr-hr.html'
  },
  {
    value: 2,
    id: 'mastercard',
    label: 'Mastercard',
    logo: <Mastercard width={56} height={45} />,
    link: 'https://www.mastercard.hr/hr-hr.html'
  },
  {
    value: 3,
    id: 'visa',
    label: 'Visa',
    logo: <Visa width={80} height={45} />,
    link: 'https://www.visa.com.hr'
  },
  {
    value: 4,
    id: 'diners',
    label: 'Diners',
    logo: <Diners width={98} height={45} />,
    link: 'https://www.diners.hr/'
  },
  {
    value: 5,
    id: 'amex',
    label: 'American Express',
    logo: <AmericanExpressCard width={45} height={45} />,
    link: 'https://www.americanexpress.com/'
  }
]

import PropTypes from 'prop-types'
import React from 'react'
import { FooterWrapper, Link, Logo, CardLogosWrapper } from './style'

import SaferpayCardsEnum from 'Rentlio/utils/saferpayCardsEnum.js'

const SaferpayFooter = ({ selectedCardTypes }) => {
  const creditCards = selectedCardTypes.split(',').map(cardId => SaferpayCardsEnum.find(card => card.id === cardId))

  return (
    <FooterWrapper>
      <CardLogosWrapper>
        {creditCards.map(card => (
          <Link href={card.link} key={card.id}>
            <Logo>{card.logo}</Logo>
          </Link>
        ))}
      </CardLogosWrapper>
    </FooterWrapper>
  )
}

SaferpayFooter.propTypes = {
  selectedCardTypes: PropTypes.string
}

export default SaferpayFooter

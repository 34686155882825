import React, { useEffect } from 'react'
import { SpinnerOverlay, Overlay } from './style'
import Animation from 'Rentlio/components/UI/Animation'
import Spinner from 'Rentlio/components/UI/Spinner'

const Saferpay3DSHandler = () => {
  useEffect(() => {
    window.parent.postMessage({ message: 'close' }, '*')
  }, [])

  return (
    <>
      <Overlay />
      <Animation show={true} animationType={'fadeInOut'} isModal>
        <SpinnerOverlay>
          <Spinner width={70} disableScroll color={'#07587A'} />
        </SpinnerOverlay>
      </Animation>
    </>
  )
}

export default Saferpay3DSHandler

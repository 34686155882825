import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getBookErrors, getPaymentDetails } from '../../../utils/getBookDetails'
import {
  BookNowButton,
  GuestDetailsTitle,
  PaymentDetailsTitle,
  PaymentInfoWrapper,
  BookNowWrapper,
  PaymentInformation,
  Link,
  SpinnerOverlay,
  CheckboxWrapper
} from './style'
import getCardExpirationDateValue from './helper/getCardExpirationDateValue'
import TermsAndConditions from './TermsAndConditions'
import SaferpayPayment from 'Rentlio/screen/payment/components/Payment/Saferpay'
import CreditCard from 'Rentlio/components/CreditCard'
import { FormError, FormGroup, FormLabel, FormRow } from 'Rentlio/components/UI/form'
import BankTransfer from 'Rentlio/components/BankTransfer'
import BookingErrors from 'Rentlio/screen/payment/containers/bookingErrors'
import Checkbox from 'Rentlio/components/UI/Checkbox'
import Email from 'Rentlio/components/Icons/email'
import Phone from 'Rentlio/components/Icons/phone'
import { Input } from 'Rentlio/components/UI/input'
import InputWithIcon from 'Rentlio/components/UI/InputWithIcon'
import Select from 'Rentlio/components/UI/Select'
import Animation from 'Rentlio/components/UI/Animation'
import Spinner from 'Rentlio/components/UI/Spinner'
import RadioGroup from 'Rentlio/components/UI/RadioGroup'
import { Textarea } from 'Rentlio/components/UI/textarea'
import { getValidationErrors } from 'Rentlio/helper/validator'
import { getCardNumberIssuer } from 'Rentlio/utils/cardNumber'
import { getCheckInHoursOptions } from 'Rentlio/utils/checkInHoursParser'
import { CountriesEnum } from 'Rentlio/utils/countriesEnum'
import {
  BE_CREDIT_CARD,
  BE_BANK_TRANSFER,
  BE_MONRI,
  BE_STRIPE,
  BE_M2PAY,
  BE_WSPAY,
  BE_SAFERPAY
} from 'Rentlio/utils/paymentTypesEnum'
import { LanguageContext } from 'Rentlio/context/LanguageContext'
import MonriPayment from 'Rentlio/screen/payment/components/Payment/MonriPayment'
import StripePayment from 'Rentlio/screen/payment/components/Payment/StripePayment'
import M2PayPayment from 'Rentlio/screen/payment/components/Payment/M2PayPayment'
import { scrollTo } from 'Rentlio/utils/scrollTo'

class PaymentInfo extends Component {
  static contextType = LanguageContext
  state = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    country: '',
    address: '',
    city: '',
    postalCode: '',
    estimatedTimeOfArrival: '',
    note: '',
    cardholderName: '',
    cardNumber: '',
    cvv: '',
    expirationDate: '',
    cardholderSameAsGuest: true,
    termsAccepted: false,
    loading: false,
    selectedPaymentMethod: 1,
    selectedPaymentMethodSettings: {},
    errors: {},
    gtmCheckoutEventSent: false,
    termsRead: false,
    hasScroll: false
  }

  componentDidMount = () => {
    this.setPaymentDetailsFromProps()
    this.fillSelectedPaymentMethod()
  }

  componentDidUpdate(prevProps) {
    const {
      receipt: { bookingEngineId }
    } = this.props

    if (prevProps.receipt.bookingEngineId !== bookingEngineId) {
      this.fillSelectedPaymentMethod()
    }
    this.sendGTMPaymentInputEvent()

    if (prevProps.bookingEngine.termsOfService !== this.props.bookingEngine.termsOfService) {
      this.setState({ termsAccepted: false })
      if (this.state.hasScroll) {
        this.setState({ termsRead: false })
      } else {
        this.setState({ termsRead: true })
      }
    }
  }

  sendGTMPaymentInputEvent() {
    const { sendPaymentFormInputEvent } = this.props

    if (this.state.gtmCheckoutEventSent) {
      return
    }

    if (
      this.state.firstName === '' &&
      this.state.lastName === '' &&
      this.state.email === '' &&
      this.state.phone === '' &&
      this.state.country === '' &&
      this.state.address === '' &&
      this.state.city === '' &&
      this.state.postalCode === '' &&
      this.state.estimatedTimeOfArrival === '' &&
      this.state.note === '' &&
      this.state.cardholderName === '' &&
      this.state.cardNumber === '' &&
      this.state.cvv === '' &&
      this.state.expirationDate === ''
    ) {
      return
    }

    this.setState({ gtmCheckoutEventSent: true })
    sendPaymentFormInputEvent()
  }

  componentWillUnmount = () => {
    this.savePaymentDetails()
  }

  fillSelectedPaymentMethod = () => {
    const filteredPaymentMethods = this.filteredPaymentMethods()
    if (filteredPaymentMethods.length === 0) {
      return
    }

    this.setState({ selectedPaymentMethod: filteredPaymentMethods[0].id }, () => this.savePaymentDetails())

    if (filteredPaymentMethods[0].settings) {
      this.setState({ selectedPaymentMethodSettings: filteredPaymentMethods[0].settings })
    }
  }

  setPaymentDetailsFromProps = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      cardholderName,
      cardNumber,
      cvv,
      expirationDate
    } = this.props.paymentDetails

    this.setState({
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      cardholderName,
      cardNumber,
      cvv,
      expirationDate
    })
  }

  savePaymentDetails = () => {
    const { updatePaymentInfo } = this.props

    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      cardholderName,
      cardNumber,
      cvv,
      expirationDate,
      selectedPaymentMethod
    } = this.state

    const guestDetails = {
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      cardholderName,
      cardNumber,
      cvv,
      expirationDate,
      selectedPaymentMethod
    }
    updatePaymentInfo(guestDetails)
  }

  book = () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      termsAccepted,
      loading,
      selectedPaymentMethod
    } = this.state

    const guestDetails = {
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note
    }

    if (loading) {
      return
    }

    // If Bank Transfer is selected add only payment method type
    const paymentDetails = this.getReservationsPaymentDetails()

    // Maybe we can solve this better, if bank transfer is selected DO NOT add CreditCard details to validation
    // because we won't be able to Book
    const errors = getBookErrors(selectedPaymentMethod, guestDetails, paymentDetails, termsAccepted)

    if (errors !== true) {
      this.setState({ errors })
      scrollTo(document.documentElement, 0, 500)
      return
    } else {
      this.setState({ errors: {} })
    }

    this.setState({ loading: true }, () => {
      // if we are paying with monri we are requesting tokenization and then on approved from MonriPayment we are triggering book reservation
      if (selectedPaymentMethod === BE_MONRI || selectedPaymentMethod === BE_WSPAY) {
        // This is ONE TIME ONLY HACK - we wanted to  keep  monri stuff  in monri component - do not do this again
        return this.monriPayment && this.monriPayment.submitPayment(guestDetails, paymentDetails)
      }
      // if we are paying with stripe first we need to fetch client secret, then submit stripe payment
      // and then on success send customer ID to rentlio backend
      if (selectedPaymentMethod === BE_STRIPE) {
        return this.stripePayment && this.stripePayment.onBookPressed()
      }
      // if we are paying with m2pay
      if (selectedPaymentMethod === BE_M2PAY) {
        return this.m2payPayment && this.m2payPayment.onBookPressed(guestDetails, paymentDetails)
      }
      // if we are paying with worldline/saferpay
      if (selectedPaymentMethod === BE_SAFERPAY) {
        return this.saferpayPayment && this.saferpayPayment.onBookPressed(guestDetails, paymentDetails)
      }

      this.callBookReservationApi(guestDetails, paymentDetails)
    })
  }

  callBookReservationApi = (guestDetails, paymentDetails) => {
    const { bookReservation, locale, clearBasket, sendSuccessfulReservationEvent } = this.props
    bookReservation(guestDetails, paymentDetails)
      .then(({ success, response }) => {
        if (!success) {
          this.setState({ loading: false })

          return
        }

        // MUST be before clear basket to send correct data
        sendSuccessfulReservationEvent(response.internalId, guestDetails)
        clearBasket()
        window.location.replace(`/reservations/${response.publicId}?language=${locale}`)
      })
      .finally(() => this.setState({ loading: false }))
  }

  getReservationsPaymentDetails = () => {
    const {
      firstName,
      lastName,
      cardNumber,
      cvv,
      expirationDate,
      cardholderSameAsGuest,
      cardholderName,
      selectedPaymentMethod
    } = this.state
    return getPaymentDetails(
      selectedPaymentMethod,
      cardholderSameAsGuest,
      firstName,
      lastName,
      cardholderName,
      expirationDate,
      cvv,
      cardNumber
    )
  }

  getValidationError = key => getValidationErrors(this.state.errors, key)
  hasValidationError = key => this.getValidationError(key).length > 0

  onCardholderSameAsGuestClicked = () => {
    this.setState(prevState => ({ cardholderSameAsGuest: !prevState.cardholderSameAsGuest }))
  }

  onTermsAcceptClicked = () => {
    this.setState(prevState => ({ termsAccepted: !prevState.termsAccepted }))
  }

  isBookNowButtonEnabled = () => this.props.receipt.accomodation.items.length !== 0 && !this.state.loading

  getTosCheckboxContent = () => {
    const { translate } = this.context
    const { openTermsOfServiceModal, openPrivacyPolicyModal, isEmptyMonri } = this.props
    const { termsRead, selectedPaymentMethod } = this.state

    const shouldReadTerms =
      selectedPaymentMethod === BE_MONRI ||
      selectedPaymentMethod === BE_WSPAY ||
      (selectedPaymentMethod === BE_CREDIT_CARD && isEmptyMonri)

    return !shouldReadTerms || (shouldReadTerms && termsRead) ? (
      <>
        {translate('BookNowTOS')} <Link onClick={openTermsOfServiceModal}>{translate('Terms of service')}</Link>{' '}
        {translate('and')} <Link onClick={openPrivacyPolicyModal}>{translate('Privacy policy')}</Link>
      </>
    ) : (
      <>
        {translate('BookNowTOS')} {translate('Terms of service')} {translate('and')} {translate('Privacy policy')}
      </>
    )
  }

  handleSelectPaymentMethods = method => {
    const { isEmptyMonri } = this.props
    const paymentMethods = this.filteredPaymentMethods()
    const selectedPaymentMethod = paymentMethods && paymentMethods.find(item => item.id === method)

    if (isEmptyMonri || method === BE_MONRI || method === BE_WSPAY) {
      this.setState({
        termsAccepted: false,
        termsRead: false,
        hasScroll: false
      })
    }

    this.setState(
      {
        selectedPaymentMethod: method,
        selectedPaymentMethodSettings: selectedPaymentMethod ? selectedPaymentMethod.settings : {}
      },
      () => this.savePaymentDetails()
    )
  }

  isOnlyCreditCardPaymentMethod = () => {
    const filteredPaymentMethods = this.filteredPaymentMethods()
    return filteredPaymentMethods.length === 1 && filteredPaymentMethods.some(item => item.id === BE_CREDIT_CARD)
  }

  isOnlyBankTransferPaymentMethod = () => {
    const filteredPaymentMethods = this.filteredPaymentMethods()
    return filteredPaymentMethods.length === 1 && filteredPaymentMethods.some(item => item.id === BE_BANK_TRANSFER)
  }

  filteredPaymentMethods = () => {
    const { paymentMethods, receipt } = this.props
    return paymentMethods.filter(item => item.bookingEngineId === receipt.bookingEngineId)
  }

  showCountryDropdown = () => {
    this.selectCountry && this.selectCountry.showDropdown()
  }

  showEstimatedTimeOfArrivalDropdown = () => {
    this.estimatedTimeOfArrival && this.estimatedTimeOfArrival.showDropdown()
  }

  handleCreditCardExpirationDate = e => {
    const { expirationDate } = this.state
    const newExpirationDate = getCardExpirationDateValue(e.target.value, expirationDate)
    this.setState({ expirationDate: newExpirationDate })
  }

  handleCvvNumber = e => {
    const { cvv } = this.state
    this.setState({ cvv: e.target.value.length > 4 ? cvv : e.target.value })
  }

  termsScrolledThrough = () => {
    this.setState({ termsRead: true })
  }

  shouldDisableCheckbox = () => {
    const { isEmptyMonri } = this.props
    const { termsRead, selectedPaymentMethod, hasScroll } = this.state
    const shouldShowTerms =
      hasScroll &&
      (selectedPaymentMethod === BE_MONRI ||
        selectedPaymentMethod === BE_WSPAY ||
        (selectedPaymentMethod === BE_CREDIT_CARD && isEmptyMonri))

    return shouldShowTerms && !termsRead
  }

  showCreditCard = () => {
    const { isEmptyMonri } = this.props
    const { termsAccepted, selectedPaymentMethod } = this.state
    return BE_CREDIT_CARD === selectedPaymentMethod && (!isEmptyMonri || (isEmptyMonri && termsAccepted))
  }

  setHasScroll = () => {
    this.setState({ hasScroll: true, termsRead: false })
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      phone,
      country,
      address,
      city,
      postalCode,
      estimatedTimeOfArrival,
      note,
      cardNumber,
      cvv,
      expirationDate,
      cardholderSameAsGuest,
      cardholderName,
      termsAccepted,
      loading,
      selectedPaymentMethodSettings,
      selectedPaymentMethod,
      termsRead
    } = this.state
    const {
      prePaymentDetails,
      locale,
      openM2Pay3DSModal,
      openSaferpay3DSModal,
      bookingEngine: { currency, notePlaceholder, termsOfService },
      isEmptyMonri,
      saferpayAliasInsert
    } = this.props
    const cardIssuer = getCardNumberIssuer(cardNumber)
    const { translate } = this.context
    const filteredPaymentMethods = this.filteredPaymentMethods()
    const showPaymentMethodsSelector = filteredPaymentMethods && filteredPaymentMethods.length > 1

    return (
      <>
        <Animation show={loading} animationType={'fadeInOut'} isModal>
          <SpinnerOverlay>
            <Spinner width={70} disableScroll color={'#07587A'} />
          </SpinnerOverlay>
        </Animation>

        <PaymentInfoWrapper>
          <GuestDetailsTitle>{translate('Booker details')}</GuestDetailsTitle>
          <FormRow>
            <FormGroup>
              <FormLabel>
                <label htmlFor='firstName'>{translate('First name')}</label>
              </FormLabel>
              <Input
                id='firstName'
                value={firstName}
                onChange={e => {
                  this.setState({ firstName: e.target.value })
                }}
              />
              <FormError>{this.getValidationError('firstName')}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <label htmlFor='lastName'>{translate('Last name')}</label>
              </FormLabel>
              <Input
                id='lastName'
                value={lastName}
                onChange={e => {
                  this.setState({ lastName: e.target.value })
                }}
              />
              <FormError>{this.getValidationError('lastName')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <FormLabel>
                <label htmlFor='eMail'>E-mail</label>
              </FormLabel>
              <InputWithIcon
                idForInput='eMail'
                icon={<Email />}
                value={email}
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
              />
              <FormError>{this.getValidationError('email')}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel>
                <label htmlFor='phone'>{translate('Phone')}</label>
              </FormLabel>
              <InputWithIcon
                idForInput='phone'
                icon={<Phone />}
                value={phone}
                onChange={e => {
                  this.setState({ phone: e.target.value })
                }}
              />
              <FormError>{this.getValidationError('phone')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup>
              <FormLabel onClick={this.showCountryDropdown}>
                <label htmlFor='country'>{translate('Country')}</label>
              </FormLabel>
              <Select
                id='country'
                ref={node => (this.selectCountry = node)}
                placeholder='Select country'
                options={CountriesEnum}
                selected={country}
                selectOption={item => {
                  this.setState({ country: item.value })
                }}
                enableSearch
              />
              <FormError>{this.getValidationError('country')}</FormError>
            </FormGroup>
            <FormGroup>
              <FormLabel onClick={this.showEstimatedTimeOfArrivalDropdown}>
                <label htmlFor='estimatedTimeOfArrival'>{translate('Estimated time of arrival')}</label>
              </FormLabel>
              <Select
                id='estimatedTimeOfArrival'
                ref={node => (this.estimatedTimeOfArrival = node)}
                selected={estimatedTimeOfArrival}
                options={getCheckInHoursOptions()}
                selectOption={item => {
                  this.setState({ estimatedTimeOfArrival: item.value })
                }}
                enableSearch
              />
              <FormError>{this.getValidationError('estimatedTimeOfArrival')}</FormError>
            </FormGroup>
          </FormRow>
          <FormRow>
            <FormGroup fullWidth={true}>
              <FormLabel>
                <label htmlFor='message'>{translate('Message')}</label>
              </FormLabel>
              <Textarea
                id='message'
                value={note}
                onChange={e => {
                  this.setState({ note: e.target.value })
                }}
                placeholder={notePlaceholder}
              />
            </FormGroup>
          </FormRow>

          {!this.isOnlyCreditCardPaymentMethod() && !this.isOnlyBankTransferPaymentMethod() && (
            <>
              <PaymentDetailsTitle>
                {showPaymentMethodsSelector ? translate('Select payment method') : translate('Reservation confirm')}
              </PaymentDetailsTitle>
              {showPaymentMethodsSelector && (
                <RadioGroup
                  options={filteredPaymentMethods}
                  onSelect={this.handleSelectPaymentMethods}
                  selected={selectedPaymentMethod}
                />
              )}

              {termsOfService.length > 0 &&
                (selectedPaymentMethod === BE_MONRI ||
                  selectedPaymentMethod === BE_WSPAY ||
                  (selectedPaymentMethod === BE_CREDIT_CARD && isEmptyMonri)) && (
                  <TermsAndConditions
                    termsOfService={termsOfService}
                    termsScrolledThrough={() => this.termsScrolledThrough()}
                    termsRead={termsRead}
                    setHasScroll={this.setHasScroll}
                  />
                )}
              {(BE_MONRI === selectedPaymentMethod || BE_WSPAY === selectedPaymentMethod) && termsAccepted && (
                <MonriPayment
                  ref={node => (this.monriPayment = node)}
                  currency={currency}
                  prePaymentDetails={prePaymentDetails}
                  paymentMethodSettings={selectedPaymentMethodSettings}
                  getMonriClientSecret={this.props.getMonriClientSecret}
                  monri={this.props.monri}
                  receipt={this.props.receipt}
                  locale={locale}
                  cardholderSameAsGuest={cardholderSameAsGuest}
                  handleSameAsGuest={this.onCardholderSameAsGuestClicked}
                  cardholderName={cardholderName}
                  handleCardHolderName={e => {
                    this.setState({ cardholderName: e.target.value })
                  }}
                  address={address || ''}
                  handleAddress={e => {
                    this.setState({ address: e.target.value })
                  }}
                  city={city || ''}
                  handleCity={e => {
                    this.setState({ city: e.target.value })
                  }}
                  postalCode={postalCode || ''}
                  handlePostalCode={e => {
                    this.setState({ postalCode: e.target.value })
                  }}
                  getValidationError={this.getValidationError}
                  firstName={firstName}
                  lastName={lastName}
                  email={email}
                  phone={phone}
                  country={country ? String(country) : country}
                  callBookReservationApi={this.callBookReservationApi}
                  onMonriPaymentFailed={() => this.setState({ loading: false })}
                  isWSPay={BE_WSPAY === selectedPaymentMethod}
                />
              )}
              {BE_STRIPE === selectedPaymentMethod && (
                <StripePayment
                  ref={node => (this.stripePayment = node)}
                  currency={currency}
                  paymentMethodSettings={selectedPaymentMethodSettings}
                  paymentDetails={this.getReservationsPaymentDetails()}
                  getStripeClientSecret={this.props.getStripeClientSecret}
                  stripe={this.props.stripe}
                  receipt={this.props.receipt}
                  locale={locale}
                  cardholderSameAsGuest={cardholderSameAsGuest}
                  handleSameAsGuest={this.onCardholderSameAsGuestClicked}
                  cardholderName={cardholderName}
                  handleCardHolderName={e => {
                    this.setState({ cardholderName: e.target.value })
                  }}
                  handleAddress={e => {
                    this.setState({ address: e.target.value })
                  }}
                  handleCity={e => {
                    this.setState({ city: e.target.value })
                  }}
                  handlePostalCode={e => {
                    this.setState({ postalCode: e.target.value })
                  }}
                  getValidationError={this.getValidationError}
                  guestDetails={{
                    firstName,
                    lastName,
                    email,
                    phone,
                    country: country ? String(country) : country,
                    address: address || '',
                    city: city || '',
                    postalCode: postalCode || '',
                    estimatedTimeOfArrival,
                    note
                  }}
                  callBookReservationApi={this.callBookReservationApi}
                  onStripePaymentFailed={() => this.setState({ loading: false })}
                />
              )}
              {BE_M2PAY === selectedPaymentMethod && (
                <M2PayPayment
                  ref={node => (this.m2payPayment = node)}
                  currency={currency}
                  paymentMethodSettings={selectedPaymentMethodSettings}
                  paymentDetails={this.getReservationsPaymentDetails()}
                  getM2PayClientSecret={this.props.getM2PayClientSecret}
                  m2pay={this.props.m2pay}
                  receipt={this.props.receipt}
                  locale={locale}
                  cardholderSameAsGuest={cardholderSameAsGuest}
                  handleSameAsGuest={this.onCardholderSameAsGuestClicked}
                  cardholderName={cardholderName}
                  handleCardHolderName={e => {
                    this.setState({ cardholderName: e.target.value })
                  }}
                  handleAddress={e => {
                    this.setState({ address: e.target.value })
                  }}
                  handlePostalCode={e => {
                    this.setState({ postalCode: e.target.value })
                  }}
                  handleCity={e => {
                    this.setState({ city: e.target.value })
                  }}
                  getValidationError={this.getValidationError}
                  guestDetails={{
                    firstName,
                    lastName,
                    email,
                    phone,
                    country: country ? String(country) : country,
                    address: address || '',
                    city: city || '',
                    postalCode: postalCode || '',
                    estimatedTimeOfArrival,
                    note
                  }}
                  callBookReservationApi={this.callBookReservationApi}
                  onM2PayPaymentFailed={() => this.setState({ loading: false })}
                  openM2Pay3DSModal={openM2Pay3DSModal}
                />
              )}
              {BE_SAFERPAY === selectedPaymentMethod && (
                <SaferpayPayment
                  ref={node => (this.saferpayPayment = node)}
                  paymentMethodSettings={selectedPaymentMethodSettings}
                  receipt={this.props.receipt}
                  callBookReservationApi={this.callBookReservationApi}
                  saferpayAliasInsert={saferpayAliasInsert}
                  openSaferpay3DSModal={openSaferpay3DSModal}
                  onSaferpayPaymentFailed={() => this.setState({ loading: false })}
                />
              )}
              {BE_BANK_TRANSFER === selectedPaymentMethod && (
                <BankTransfer currency={currency} prePaymentDetails={prePaymentDetails} />
              )}
              {this.showCreditCard() && (
                <CreditCard
                  cardholderSameAsGuest={cardholderSameAsGuest}
                  handleSameAsGuest={this.onCardholderSameAsGuestClicked}
                  cardholderName={cardholderName}
                  handleCardHolderName={e => {
                    this.setState({ cardholderName: e.target.value })
                  }}
                  handleCardNumber={e => this.setState({ cardNumber: e.target.value })}
                  cardIssuer={cardIssuer}
                  cvv={cvv}
                  handleCvvNumber={this.handleCvvNumber}
                  expirationDate={expirationDate}
                  handleExpirationDate={this.handleCreditCardExpirationDate}
                  getValidationError={this.getValidationError}
                />
              )}
            </>
          )}
          {this.isOnlyBankTransferPaymentMethod() && (
            <>
              <PaymentDetailsTitle>{translate('Reservation confirm')}</PaymentDetailsTitle>
              <BankTransfer currency={currency} prePaymentDetails={prePaymentDetails} />
            </>
          )}
          {this.isOnlyCreditCardPaymentMethod() && (
            <>
              <PaymentDetailsTitle>{translate('Reservation confirm')}</PaymentDetailsTitle>
              {termsOfService.length > 0 && isEmptyMonri && (
                <TermsAndConditions
                  termsOfService={termsOfService}
                  termsScrolledThrough={() => this.termsScrolledThrough()}
                  termsRead={termsRead}
                  setHasScroll={this.setHasScroll}
                />
              )}
              {this.showCreditCard() && (
                <CreditCard
                  cardholderSameAsGuest={cardholderSameAsGuest}
                  handleSameAsGuest={this.onCardholderSameAsGuestClicked}
                  cardholderName={cardholderName}
                  handleCardHolderName={e => {
                    this.setState({ cardholderName: e.target.value })
                  }}
                  handleCardNumber={e => this.setState({ cardNumber: e.target.value })}
                  cardIssuer={cardIssuer}
                  cvv={cvv}
                  handleCvvNumber={this.handleCvvNumber}
                  expirationDate={expirationDate}
                  handleExpirationDate={this.handleCreditCardExpirationDate}
                  getValidationError={this.getValidationError}
                />
              )}
            </>
          )}

          <BookNowWrapper>
            <BookNowButton onClick={this.book} enabled={this.isBookNowButtonEnabled()}>
              {translate('Book now')}
            </BookNowButton>
            <CheckboxWrapper>
              <Checkbox
                isChecked={termsAccepted}
                click={this.onTermsAcceptClicked}
                text={this.getTosCheckboxContent()}
                disabled={this.shouldDisableCheckbox()}
              />
            </CheckboxWrapper>

            <FormError>{this.getValidationError('termsAccepted')}</FormError>
          </BookNowWrapper>
          <BookingErrors />
          {BE_BANK_TRANSFER !== selectedPaymentMethod && BE_MONRI !== selectedPaymentMethod && (
            <PaymentInformation>{translate('PaymentInformationNotice')}</PaymentInformation>
          )}
          {BE_MONRI === selectedPaymentMethod && (
            <PaymentInformation>{translate('PaymentInformationMonri')}</PaymentInformation>
          )}
        </PaymentInfoWrapper>
      </>
    )
  }
}

PaymentInfo.propTypes = {
  bookReservation: PropTypes.func,
  bookingEngine: PropTypes.object,
  updatePaymentInfo: PropTypes.func,
  receipt: PropTypes.object,
  paymentDetails: PropTypes.shape({
    firstName: PropTypes.String,
    lastName: PropTypes.String,
    email: PropTypes.String,
    phone: PropTypes.String,
    country: PropTypes.String,
    address: PropTypes.String,
    city: PropTypes.String,
    postalCode: PropTypes.String,
    estimatedTimeOfArrival: PropTypes.String,
    note: PropTypes.String,
    cardholderName: PropTypes.String,
    cardNumber: PropTypes.String,
    cvv: PropTypes.String,
    expirationDate: PropTypes.String
  }),
  paymentMethods: PropTypes.arrayOf(PropTypes.object),
  locale: PropTypes.string,
  openTermsOfServiceModal: PropTypes.func,
  openPrivacyPolicyModal: PropTypes.func,
  prePaymentDetails: PropTypes.object,
  clearBasket: PropTypes.func,
  getMonriClientSecret: PropTypes.func,
  monri: PropTypes.object,
  stripe: PropTypes.object,
  m2pay: PropTypes.object,
  getStripeClientSecret: PropTypes.func,
  getM2PayClientSecret: PropTypes.func,
  sendSuccessfulReservationEvent: PropTypes.func,
  sendPaymentFormInputEvent: PropTypes.func,
  openM2Pay3DSModal: PropTypes.func,
  saferpayAliasInsert: PropTypes.func,
  openSaferpay3DSModal: PropTypes.func,
  termsOfService: PropTypes.string,
  selectedPaymentMethod: PropTypes.number,
  isEmptyMonri: PropTypes.bool
}

export default PaymentInfo

export const getCustomFieldsStyle = theme => {
  return {
    '#fields-card-number, #fields-expiration': `
      display: inline;
    `,
    'input::-webkit-input-placeholder': `
      color: ${theme.colors.textColor};
      opacity: 0.5;
      font-size: 14px;
    `,
    'input.form-control': `
      border-radius: 10px;
      padding: 21px 15px;
      font-size: 14px;
      line-height: ${theme.fonts._16};
      border: 1px solid ${theme.colors.border};
      transition: all ${theme.animations.defaultTransition};
    `,
    'input.is-focused': `
      border: 1px solid ${theme.colors.secondary};
      outline: none;
    `,
    'input.is-invalid': `
      color: ${theme.colors.alertColor};
    `
  }
}
